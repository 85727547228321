:root {
	--highlight-color: #f97373;
}

/* ================================================================== */
/* Leaderboard styling */

.LeaderboardContainer {
	float: left;
	margin: 0 calc(50% - 700px);
	width: 1400px;
	min-width: 1400px;
	color: #c8c8c8;
	margin-top: 32px;
	display: block;
	flex-direction: column;
}

.LeaderboardAccountHeader {
	float: left;
	position: relative;
	width: 100%;
	z-index: 1;
	display: flex;
	flex-direction: row;
}

.LeaderboardAccountHeaderIconContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.LeaderboardAccountHeaderIconTier {
	height: 60px;
	width: 60px;
	margin-left: 20px;
	position: absolute;
	top: -24px;
}

.LeaderboardAccountHeaderIconLevelIndicator {
	align-items: center;
	justify-content: center;
	height: 20px;
	width: 48px;
	margin-left: 20px;
	border: 1px solid white;
	z-index: 5;
	border-radius: 12px;
	position: absolute;
	bottom: -10px;
	background-color: rgba(26,32,44,1);
}

.LeaderboardChallengeMainIconBoxshadow {
	box-shadow: 0 0 20px 8px var(--player-level);
}

.LeaderboardAccountHeaderIcon {
	border: 3px solid #fff;
	border-radius: 10px;
	box-shadow: 0 0 15px var(#fff);
	height: 94px;
	margin-left: 20px;
	width: 94px;
}

.LeaderboardAccountContainer {
	display:flex;
	flex-direction: column;
	margin-left: 24px;
}

.LeaderboardAccountHeaderTitleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;;
}

.LeaderboardAccountHeaderTitle {
	display: inline-flex;
	color: #fff;
	float: left;
	font-size: 2rem;
	margin: 5px 12px 5px 0;
	text-shadow: 0 0 5px #000;
	width: 100%;
	justify-content: flex-start;
}

.LeaderboardAccountHeaderFlareTitle {
	display: inline-flex;
	font-style: italic;
	margin: 8px 5px 5px 12px;
}

.LeaderboardAccountHeaderTitleIconContainer {
	display: inline-flex;
	position: relative;
	margin-top: 8px;
	z-index: 5;
}

.LeaderboardAccountHeaderTitleItemIcon {
	border-radius: 50%;
	margin-right: 8px;
	width: 36px;
	height: 36px;
}

.LeaderboardMainSection {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.LeaderboardChallenges {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	float: left;
	justify-content: flex-start;
	margin-bottom: 10px;
	margin-top: 5px;
	width: 100%;
}

.LeaderboardChallengeFilter {
	background: rgb(20, 39, 65);
	border: 1px solid transparent;
	max-height: 36px;
	height: 36px;
	border-radius: 20px;
	box-sizing: border-box;
	color: #c8c8c8;
	font-size: 1rem;
	margin: 10px 5%;
	outline: none;
	padding: 8px 20px;
	width: 70%;
}

.LeaderboardChallengeGrid {
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
}

.LeaderboardChallengeItem {
	position: relative;
	display: flex;
	margin-top: 20px;
	width: 500px;
	flex-direction: column;
	align-items: flex-start;
	color: white;
}

.LeaderboardChallengeItem:nth-child(even) {
	margin-left: 60px;
}

.LeaderboardChallengeItemTitleContainer {
	margin-bottom: 8px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.LeaderboardChallengeItemIconContainer {
	display: inline-flex;
	position: relative;
	left: -8px;
	z-index: 5;
}

.LeaderboardChallengeItemTitleBox {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-bottom: 1px solid #c8c8c8;
	width: 100%;
}

.LeaderboardChallengeItemIcon {
	border-radius: 50%;
	width: 48px;
	height: 48px;
}

.LeaderboardChallengeItemIconBoxshadow {
	box-shadow: 0 0 6px 1px var(--player-level);
}

.LeaderboardChallengeItemTitle {
	display: inline-flex;
	font-size: 22px;
	font-weight: 400;
	width: 100%
}

.LeaderboardChallengeItemDescription {
	margin-top: 2px;
	font-weight: 100;
	display: inline-flex;
	font-size: 10px;
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: start;
}

.LeaderboardChallengeItemLeaderListHeaderRow {
	display: flex;
	flex-direction: row;
	width: 100%;
	font-size: 12px;;
	margin-bottom: 2px;
	align-items: flex-start;
}

.LeaderboardChallengeItemLeaderListCol1 {
	display: flex;
	align-items: flex-start;
	width: 20%;
}
.LeaderboardChallengeItemLeaderListCol2 {
	display: flex;
	align-items: center;
	width: 60%;
}
.LeaderboardChallengeItemLeaderListCol3 {
	display: flex;
	align-items: center;
	width: 20%;
	font-size: 14px;
}

.LeaderboardChallengeItemLeaderList {
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	box-sizing: border-box;
	border: 2px solid #c8c8c8;
	border-radius: 2px;
	display: flex;
}

.LeaderboardChallengeItemLeaderListItem {
	padding: 6px 8px;
	border-top: 1px solid #c8c8c8;
	width: 100%;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	display: flex;
	background: var(--current-player-indicator-background);
  background: linear-gradient(90deg, var(--current-player-indicator-background) 60%, var(--player-level) 100%) !important;
}

.CURRENTSUMMONER {
	color: var(--highlight-color);
	text-shadow: 3px 2px 8px rgb(20, 39, 65);
	--current-player-indicator-background: rgb(20, 39, 65); 
}

.OTHERSUMMONER {
	--current-player-indicator-background: rgba(26,32,44,1); 
}

.LeaderboardChallengeItemLeaderListItemSummonerIcon {
	border: 1px solid #fff;
	border-radius: 6px;
	height: 24px;
	width: 24px;
	margin-right: 8px;
}

.LeaderboardChallengeItemLeaderListItemValueIcon {
	height: 24px;
	width: 24px;
	margin-right: 8px;
	margin-top: 2px
}

.LeaderboardLeftSidebar {
	position: sticky;
	top: 60px;
	float: left;
	overflow: hidden;
	width: 300px;
	display: flex;
}

.LeaderboardLeftSidebarSelectors {
	border-radius: 8px;
	box-sizing: border-box;
	margin: 15px 10px 10px;
	overflow: hidden;
	padding: 0 10px 10px;
	width: calc(100% - 20px);
	display: flex;
	flex-direction: column;
}

.LeaderboardLeftSidebarCategory {
	float: left;
	margin-bottom: 20px;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.LeaderboardLeftSidebarSingleSelector {
	border: 2px solid transparent;
	border-radius: 10px;
	color: #c8c8c8;
	cursor: pointer;
	display: flex;
	font-size: 1rem;
	margin: 5px 0;
	padding: 5px 10px;
	text-align: left;
	transition: padding-left .25s;
	white-space: nowrap;
	align-items: center;
}

.LeaderboardLeftSidebarSingleSelector:hover {
	background-color: rgb(20, 39, 65);
	color: #fff;
}

.SELECTED {
	background-color: rgb(20, 39, 65);
	color: #fff;
	padding-left: 20px;
}

.LeaderboardLeftSidebarCategoryHeader {
	color: #fff;
	font-size: .8rem;
	font-weight: 700;
	text-align: left;
	width: 100%;
}

.LeaderboardLeftSidebarSelectorIcon {
	filter: invert(99%) sepia(3%) saturate(166%) hue-rotate(52deg) brightness(117%) contrast(100%);
	float: left;
	height: 1rem;
	margin-right: 5px;
	width: 1rem;
}

.LeaderboardLeftSidebarSelectorIconSVG {
	filter: invert(99%) sepia(3%) saturate(166%) hue-rotate(52deg) brightness(117%) contrast(100%);
	float: left;
	height: 1rem;
	margin-right: 5px;
	width: 1rem;
}

.LeaderboardLeftSidebarCheckbox {
	display: flex;
}

/* ================================================================== */
/* general challenge view styling */
.challengeViewContainer {
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ChallengeDetailsCard {
	color: white !important;
	display: flex;
	width: 560px;
	height: 250px;
	justify-content: center;
	align-items: center;
	overflow: visible !important;
	position: relative;
}

.ChallengeDetailsIconContainer {
	position: absolute;
	top: -36px;
	left: 250px;
	z-index: 5;
}

/* ================================================================== */
/* Mastery table styling */
.masteryTable {
	list-style: none;
	width: 80%;
	margin: auto;
	margin-top: 40px;
	margin-bottom: 20px;
}

.masteryList {
	overflow-x: clip !important;
}

.tableTitle {
	width: 100%;
	text-align: start;
	color: whitesmoke;
	display: flex;
	justify-content: space-between;
}

.titleHintColorbox {
	width: 16px;
	height: 16px;
	background-color: var(--highlight-color);
	border: 1px solid whitesmoke;
	border-radius: 50%;
	margin: auto;
	margin-right: 6px;
}

.masteryListCheckbox {
	display: flex;
	color: white;
}

.masteryListChampionPortrait {
	width: 24px;
	height: 24px;
	border-radius: 3px;
}

.masteryTokenContainer {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-left: 4px;
	background-color:  darkslategrey;
}

.masteryTokenContainerMissing {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-left: 4px;
	background-color:  grey;
}

.masteryTokenImageMissing {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-top: 1px;
	filter: brightness(1%) opacity(100%);
	-webkit-filter: brightness(1%) opacity(100%);
}

.masteryTokenImage {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-top:1px;
}

.masteryTokensContainer {
	display: flex;
	flex-direction: row;
}

.tableHovercard {
	padding: 5px;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
}

.tableHovercardInner {
	font-size: 0.9rem;

}
/* ================================================================== */
/* Diff Challenges Styling */
.DiffChallengeCardContainer {
	display: flex;
	flex-direction: row;
	margin: auto;
	margin-top: 60px;
	max-width: 1600px;
	justify-content: center;
}

.DiffChallengeCard {
	color: white !important;
	display: flex;
	width: 300px;
	height: 250px;
	margin-right: 20px;
	background-color: #00264c;
	justify-content: center;
	align-items: center;
	overflow: visible !important;
	position: relative;
}

.ChallengeIconContainer {
	position: absolute;
	top: -36px;
	left: 114px;
	z-index: 5;
}

.ChallengeIcon {
	border-radius: 50%;
	width: 72px;
	height: 72px;
}

.DiffChallengeCardPCTProgress {
	width: 200px;
	max-width: 200px;
	margin: auto;
	margin-top: 12px;
}

/* ================================================================== */
/* Diff Challenges Styling */
.LHFCardContainer {
	display: flex;
	flex-direction: row;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: center;
	max-width: 2000px;
	flex-wrap: wrap;
}

.LHFCard {
	color: white !important;
	display: flex;
	flex-direction: column;
	width: 350px;
	height: 300px;
	margin-right: 20px;
	background-color: #1a202c !important;
	justify-content: center;
	align-items: center;
	margin-bottom: 60px;
	box-sizing: border-box;
	overflow: visible !important;
	position: relative;
}

.LHFChallengeIconContainer {
	position: absolute;
	top: -36px;
	left: 134px;
	z-index: 5;
}

.LHFChallengeIcon {
	border-radius: 50%;
	width: 72px;
	height: 72px;
}

.LHFCardPCTProgress {
	width: 200px;
	max-width: 200px;
	margin: auto;
	margin-top: 12px;
}

.LHFFilterSingleSelector {
	border: 2px solid transparent;
	border-radius: 10px;
	color: #c8c8c8;
	cursor: pointer;
	display: flex;
	font-size: 1rem;
	padding: 0px 10px;
	text-align: left;
	transition: padding-left .25s;
	white-space: nowrap;
	align-items: center;
}

.LHFFilterContainer {
	display: flex;
	flex-direction: row;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: center;
	flex-wrap: wrap;
}

.LHFFilterSelectors {
	border-radius: 8px;
	box-sizing: border-box;
	margin: 15px 10px 10px;
	overflow: hidden;
	padding: 0 10px 10px;
	display: flex;
}

.LHFCategoryHeader {
	color: #fff;
	font-weight: 700;
	text-align: left;
	margin-right: 10px;
	font-size: 1.2rem;
}

.LHFCategory {
	float: left;
	overflow: hidden;
	display: flex;
	margin-right: 20px;
}

.LHFFilterSelectorIconSVG {
	filter: invert(99%) sepia(3%) saturate(166%) hue-rotate(52deg) brightness(117%) contrast(100%);
	float: left;
	height: 1rem;
	margin-right: 5px;
	width: 1rem;
}

.LHFCornerIcon {
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 1;
	color: #fff;
  }
  
  .LHFCornerIcon img,
  .LHFCornerIcon svg {
	width: 24px;
	height: 24px;
	object-fit: contain;
	fill: currentColor;
  }


/* ==================================================================== */
/* Rated component styling */

.IRON {
	--player-level: #696969;
}

.BRONZE {
	--player-level: #8c5139;
}

.SILVER {
	--player-level: rgba(129,152,156,1);
}

.GOLD {
	--player-level: rgba(206,134,57,1);
}

.PLATINUM {
	--player-level: rgba(32,140,153,1);
}

.DIAMOND {
	--player-level: #576ace;
}

.MASTER {
	--player-level: #a137c2;
}

.GRANDMASTER {
  --player-level: #e17283;
}

.CHALLENGER {
 --player-level: #f4c873;
}

.NONE {
	--player-level: #1A2E63;
}

.ratedBorder {
	border: 2px solid var(--player-level) !important;
	box-shadow: 0 0 3px 1px var(--player-level) !important;
	background: rgb(26,32,44) !important;
  background: linear-gradient(180deg, rgba(26,32,44,1) 0%, var(--player-level) 100%) !important;

}

.ratedText {
	color: var(--player-level);
}

.ratedBoxshadow {
	box-shadow: 0 0 15px 3px var(--player-level);
}

.ratedBackground {
	background-color: var(--player-level) !important;
}

.highlightedValue {
	text-shadow: 3px 2px 8px rgba(0, 0, 0, 1);
}

/* ================================================================== */
/* Virtuoso */

.virtuosoContainer {
	display: flex;
	flex-wrap: wrap;
	margin-top: 80px;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
	
}

.virtuosoCard{
	color: white !important;
	display: flex;
	flex-direction: column;
	width: 1000px;
	height: 520px;
	margin-right: 20px;
	background-color: #1a202c !important;
	justify-content: center;
	align-items: center;
	margin-bottom: 60px;
	box-sizing: border-box;
	overflow: visible !important;
	position: relative;
}

.virtuosoChallengeIconContainer {
	position: absolute;
	top: -36px;
	left: 464px;
	z-index: 5;
}

.virtuosoChampSectionContainer {
	width: 800px;
	height: 200px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-content:center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 40px;
}
.virtuosoChampContainer {
	border: 2px solid #c28f2c;
	background-color: #131722;
	color: #c28f2c;
	border-radius:10px;
	width: 220px;
	height: 124px;
	margin-right: 20px;
	margin-top: 20px;
	padding-left: 8px;
	padding-right: 8px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.virtuosoChampPortrait {
	width: 76px;
	height: 76px;
	border-radius: 5px;
}

.virtuosoChampDetails{
	padding-left: 8px;
	display: flex;
	flex-direction: column;
	justify-items: left;
	align-items: start;
}

.virtuosoOverallChampContainer {
	display: flex;
	flex-direction: column;
}

.virtuosoInnerChampContainer {
	display: flex;
	flex-direction: row;
}

.virtuosoMasteryContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.virtuosoMasteryIcon {
	width: 16px;
	height: 16px;
	margin-right: 4px;
}

.virtuosoMarksContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.virtuosoMarksIcon {
	width: 20px;
	height: 20px;
	margin-right: 4px;
}

.gradesContainer {
	display: flex;
	flex-direction: row;
	margin-top: 3px;
	margin-bottom: 4px;
}

.gradeContainer {
	width: 24px;
	height: 24px;
	position: relative;
}

.grade {
	width: 24px;
	height: 24px;
}

.gradeOverlayContainer {
	position: relative;
  display: inline-block;
}

.gradeOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
}

/* ================================================================== */
/* Challenge Cards */
.ChallengeSelectionCard {
	color: white !important;
	display: flex;
	flex-direction: column;
	width: 300px;
	height: 200px;
	margin-right: 20px;
	background-color: #1a202c !important;
	justify-content: center;
	align-items: center;
	margin-bottom: 60px;
	box-sizing: border-box;
	overflow: visible !important;
	position: relative;
	cursor: pointer;
}

.ChallengeSelectionIconContainer {
	position: absolute;
	top: -36px;
	left: 114px;
	z-index: 5;
}

.challengeSelectionView {
	display: flex;
	flex-wrap: wrap;;
	justify-content: center;
	display: inline-flex;
	margin: 80px auto auto;
	grid-gap: 20px;
	max-width: 1300px;
}

/* ================================================================== */
/* Table Sorting */
.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-icon {
	color: inherit !important;
}

/* ================================================================== */
/* Progress bar styling */
.progressBarInner {
	background-color: var(--highlight-color);
	height: 100%;
	width: 100%;
}

.progressBar {
	background-color: rgb(220, 220, 220);
	height: 15px;
	width: 100%;
}

.progressContainer {
	padding: 4px 0;
}

/* ================================================================== */
/* Search Bar */
.summonerSearchBar {
	height: 80px;
	width: 100%;
	position: fixed;
	top: 0;
	background-color: #0e141b;
	display: flex;
	box-shadow: 0 1rem 5rem -0.5rem #000;
    border-bottom: 1px solid #29303d;
	z-index: 999;
}

.summonerInputInSearchbarContainer {
	margin: auto;
	display: flex;
	width: 50%;
}

.summonerInputInSearchbar {
	padding: 10px 60px 10px 24px;
	height: 30px;
	font-size: 18px;
	font-weight: 400;
	border-radius: 30px 30px 30px 30px;
	border: 1px solid #5773ff;
	width: 100%;
	background-color: rgb(20, 24, 33);
	color: whitesmoke;
}

.searchBarRegionSelector {
	margin-left: 16px;
	margin-top: 6px;
	background-color: whitesmoke;
	padding: 0;
}

/* ================================================================== */
/* Summoner input styling */
.summonerInput {
	padding: 10px 60px 10px 24px;
	height: 60px;
	font-size: 20px;
	font-weight: 400;
	border-radius: 30px 30px 30px 30px;
	border: 1px solid #5773ff;
	position: relative;
	width: 80%;
    background: none;
    color: whitesmoke;
}

.summonerInputContainer {
	margin: auto;
	width: 80%;
	position: relative;
	vertical-align: middle;
	top: 40%;
}

.summonerNameLookupButton {
	margin-top: 20px;
}

.lookupButton {
	width: 200px;
	height: 50px;
}

.regionSelector {
	margin-left: 16px;
	background-color: whitesmoke;
	margin-top: 20px;
}

.spinner {
	margin: auto;
	width: 44px;
}

.spinnerContainer {
	width: 100%;
	margin-top: 100px;
	text-align: center;
}

/* ================================================================== */
/* Back button styling */
.backButtonContainer {
	margin-top: 40px;
}

.backButton {
	width: 300px;
	height: 50px;
}

.BackButtonColor {
	color: var(--highlight-color) !important;
}
.error {
	margin: auto;
	margin-bottom: 16px;
	width: 400px;
}

.mainContentContainer {
	position: relative;
  height: calc(100vh - 80px); /* Adjust the height to account for the fixed element */
  overflow-y: auto;
  overflow-x: hidden;
	top: 80px;
}

.appBackground {
	background: #0e141b;
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
}

.App {
	z-index: 1;
	background-color: transparent;
	text-align: center;
	font-family: "Helvetica Neue",Roboto,Arial,Sans-Serif !important;
	height: 100%;
	position: absolute;
	width: 100%;
	overflow: auto;
}
